import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import CollaboratorToCompany from "./CollaboratorToCompany";
import jwtDecode from "jwt-decode";

const CollaboratorDetails = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const { data, currentItems } = location.state || {};

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const [openAssociateDialog, setOpenAssociateDialog] = useState(false);
  const [associateId, setAssociateId] = useState(0);
  const [actionValor, setActionValor] = useState(1);

  const [open, setOpen] = useState(false);
  const [booleanStatus, setBooleanStatus] = useState(false);
  function handleClose() {
    setOpen(false);
  };

  const handleOpenAssociateDialog = (id) => {
    setAssociateId(id);
    setOpenAssociateDialog(true);
  };

  const handleCloseAssociateDialog = () => {
    setOpenAssociateDialog(false);
    setAssociateId(0);
  };

  const [collabPosition, setCollabPosition] = useState("N/A");

  const handleClickAssociate = (contractText, id) => {
    if (contractText == "Descontratar") {
      // alert('Deseja Mesmo Descontratar Este collaborador?');
      setActionValor(0);
    } else {
      setActionValor(1);
    }
    handleOpenAssociateDialog(id);
    setCollabPosition(data.speciality);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  if (!data) {
    return <p>Nenhum dado disponível</p>;
  }

  console.log("Curren data: ", data);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <span
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
              boxShadow: '0 0 10px rgba(0,0,0,0.05)',
              padding: '1.25rem 1rem',
              display: 'block',
              width: '100%'
            }}
          >
            Detalhes do Colaborador
          </span>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '16px' }}>
        <Grid item xs={12} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)'
        }}>
          <Grid sx={{
            width: '45px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F6921E',
            color: 'white',
            borderRadius: '50%',
            aspectRatio: '1/1'
          }}>
            {
              data.avatar ? (
                // <img src={data.avatar} />
                <span>
                  {data.name[0]}
                </span>
              ) : (
                <span>
                  {data.name[0]}
                </span>
              )
            }
          </Grid>
          <h4>{data.name}</h4>
          <small>{data.country} {" - "} {data.city}</small>
          <div>
            {`${data.postRoleDescription} `}
            <span>{data.speciality ?? 'N/A'}{` Com ${data.yearsofExperience ?? '-'} Anos de Experiência`}</span>
            <span>{` | Estado: ${data.isActive ? 'Activo' : 'Inactivo'}`}</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '18px' }}>
            {
              Array.from({ length: 5 }).map((_, index) => (
                <span style={{
                  color: index <= 3 ? '#F6921E' : '#000'
                }}>
                  {index <= 3 ? '★' : '☆'}
                </span>
              ))
            }
          </div>

          <div sytle={{ display: 'flex', alingItems: 'center', marginTop: '24px' }}>
            <a
              href={`${data.phone ? 'tel:' + data.phone : '#'}`}
              style={{
                display: 'inline-block',
                textAlign: 'center',
                backgroundColor: 'white',
                fontSize: '0.75rem',
                borderRadius: '50px',
                color: '#000',
                textDecoration: 'none',
                padding: '0.5rem 1rem',
                fontWeight: '600',
                textTransform: 'uppercase',
                border: '1px solid rgba(0,0,0,0.1)'
              }}>Ligar</a>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleClickAssociate('Contratar', data.id)}
              sx={{
                fontSize: '0.75rem',
                borderRadius: '50px',
                marginLeft: '8px'
              }}
            >
              Contratar
            </Button>
          </div>

        </Grid>
      </Grid>

      <Grid container sx={{ marginTop: '16px' }} justifyContent="space-between">
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)'
        }}>
          <div style={{ marginTop: '24px' }}>
            <h4>Descrição</h4>
            <p>
              {data.name}, Desenvolvedor muito dedicado com mais experiencias no mercado, ele está disposto a dar o seu melhor.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)',
          mt: { xs: 4, md: 0 },
        }}>
          <div style={{ marginTop: '24px' }}>
            <h4>Empresas trabalhado</h4>
            {
              data?.companies?.map((comp, index) => (
                <p>✔️ {comp?.attributes?.name} </p>
              ))
            }
          </div>
        </Grid>
        <Grid item xs={12} sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0,0,0,0.05)',
          mt: { xs: 4 },
        }}>
          <div style={{ marginTop: '24px' }}>
            <h4>Experiencias Profissional</h4>
            {
              data?.experiences?.map((exp, index) => (
                <div style={{ marginTop: '10px', borderBottom: '1px solid rgba(0,0,0,0.1)', paddingBottom: '8px' }}>
                  <small style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>✔️ {exp?.postRoleDescription} {" "} {exp?.description} At: <span style={{ color: '#F6921E', fontWeight: 'bold' }}>{exp?.expEntity}</span> || Country: <span style={{ color: '#F6921E', fontWeight: 'bold' }}> {`${exp?.country} `}</span></small>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <small>Start Date: {formatDate(exp.startDate)}</small>
                    <small>| End Date: {formatDate(exp.endDate)}</small>
                  </div>
                </div>
              ))
            }
          </div>
        </Grid>
      </Grid>

      <CollaboratorToCompany
        open={openAssociateDialog}
        handleClose={handleCloseAssociateDialog}
        idCollaborador={associateId}
        action={actionValor}
        positionCollaborator={collabPosition}
      />

    </>
  );
};


export default CollaboratorDetails;

