// CollaboratorToCompany.jsx
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Autocomplete,
  Typography,
  Box,
  TextField,
  Grid
} from '@mui/material';
import Stack from '@mui/material/Stack';

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_EMPLOYMENT_COLLAB, ASSOC_CTOC, TESTE_2, UPDATE_COMPANY_C } from './mutation.gql';
import { GET_COLLABORATORS, GET_COMP_GET } from './query.gql';
import { GET_COMPANIES, GET_USER_DATA } from "../../CompanyPage/Company_Creation_Page/query.gql";
import { toast } from 'react-toastify';
import jwtDecode from "jwt-decode";
import { useLazyQuery } from "@apollo/client";

const CollaboratorToCompany = ({ open, handleClose, idCollaborador, action }) => {
  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const [isAlreadyAssociated, setIsAlreadyAssociated] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(true);
  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [currentOption, setCurrentOption] = useState(-1);
  const [salary, setSalary] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


  useEffect(() => {
    if (open) {
      setIsAlreadyAssociated(false);
      setLoadingCheck(false);
    }
    // (alert)(idCollaborador);
    // alert(employeerID);
  }, [open]);

  useEffect(() => {
    if (open) {
      console.log(`Associando colaborador com ID: ${idCollaborador}`);
    }
  }, [idCollaborador, open]);

  const [get_companies] = useLazyQuery(GET_COMP_GET);
  const [get_collaborator] = useLazyQuery(GET_COLLABORATORS);
  const [assocCollabo] = useMutation(CREATE_EMPLOYMENT_COLLAB);;

  const loadCollabData = () => {
    get_companies({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        userId: employeerID.toString()
      },
    }).then((data) => {
      const lastId = data?.data?.companies?.data?.map(companyItem => {
        return companyItem.id;
      });
      setCurrentCompanyId(lastId.toString());
    });
  };

  const proceedAssoc = async ({ companyId, salary, startDate, endDate, positionCollaborator }) => {
    setLoadingCheck(true);
    try {
      const result = await assocCollabo({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          companyId: [companyId.toString()],
          collaboratorId: [idCollaborador.toString()],
          position: positionCollaborator ?? 'N/A',
          startDate: startDate,
          endDate: endDate,
          status: 'ativo',
          Salary: parseFloat(salary),
        },
      });

      // console.log(result.data.createEmployment.data);
      toast.success(`Colaborador contratado com Sucesso!`);
      handleClose();
    } catch (error) {
      console.error('Error creating employment:', error);
      toast.error("Erro ao tentar contratar o colaborador.");
    } finally {
      setLoadingCheck(false);
    }
  };



  const [updateCollaborator] = useMutation(UPDATE_COMPANY_C);

  const associarColaborador = async () => {
    setLoadingCheck(true);
    try {
      const { data } = await get_collaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
        },
      });

      const currentCompanies = data?.data?.collaborator?.attributes?.companies?.data.map(comp => comp.id) || [];


      const updatedCompanies = [
        ...new Set([...currentCompanies, currentCompanyId.toString()])
      ];

      await updateCollaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
          data: {
            companies: updatedCompanies,
          },
        },
      });

      toast.success(`Company associada com sucesso ao colaborador!`);
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Erro ao associar company ao colaborador:", error);
      toast.error("Erro ao associar company ao colaborador.");
    } finally {
      setLoadingCheck(false);
    }
  };


  const desassociarColaborador = async () => {
    setLoadingCheck(true); // Inicia o carregamento
    try {
      const { data } = await get_collaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
        },
      });

      // Obtém a lista de companies existentes
      const currentCompanies = data?.data?.collaborator?.attributes?.companies?.data.map(comp => comp.id) || [];

      // Remove a company da lista
      const updatedCompanies = currentCompanies.filter(companyId => companyId !== currentCompanyId.toString());

      // Chama a mutação para atualizar o colaborador
      await updateCollaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
          data: {
            companies: updatedCompanies,
          },
        },
      });

      toast.success(`Company desassociada com sucesso do colaborador!`);
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Erro ao desassociar company do colaborador:", error);
      toast.error("Erro ao desassociar company do colaborador.");
    } finally {
      setLoadingCheck(false); // Finaliza o carregamento
    }
  };


  const [companies, setCompanies] = useState([]);
  const [companiesItems, setCompaniesItems] = useState([]);

  const [getCompanys_, { loading, data }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const { companies } = data;
      setCompanies(companies.data);

      // Processar os dados diretamente aqui
      const items = companies.data.map((item) => ({
        value: item?.id,
        label: item?.attributes?.name,
      }));
      setCompaniesItems(items);
    }
  }, [data]);

  useEffect(() => {
    getCompanys_();
    loadCollabData();
  }, []);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="associate-dialog-title" style={{ textAlign: 'center' }}>
        {loadingCheck ? 'Verificando associação...' : (
          action == 0
            ? 'Deseja desassociar este colaborador da sua empresa?'
            : 'Deseja mesmo associar este colaborador à sua empresa?'
        )}
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        {loadingCheck ? (
          <CircularProgress />
        ) : isAlreadyAssociated ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Fechar
          </Button>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              {/* Autocomplete e Feedback */}
              <Grid item lg={6} xs={12}>
                <Box>
                  <Autocomplete
                    options={companiesItems}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setCurrentOption(newValue?.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione uma empresa"
                        variant="outlined"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                          },
                          '&.Mui-focused .MuiInputLabel-root': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    )}
                  />
                  {/* <Typography variant="body1" sx={{ mt: 2 }}>
                    {selectedOption
                      ? `Você selecionou: ${selectedOption.label}`
                      : 'Nenhuma opção selecionada.'}
                  </Typography> */}
                </Box>
              </Grid>

              {/* Remuneração */}
              <Grid item lg={6} xs={12}>
                <TextField
                  label="Remuneração"
                  type="number"
                  fullWidth
                  onChange={(e) => setSalary(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'primary.main',
                    },
                  }}
                />
              </Grid>

              {/* Outro Campo de Texto */}
              <Grid item lg={6} xs={12}>
                <TextField
                  label="Data Inicio"
                  type="date"
                  fullWidth
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'primary.main',
                    },
                  }}
                />
              </Grid>

              {/* Campo de Data */}
              <Grid item lg={6} xs={12}>
                <TextField
                  label="Data Fim"
                  type="date"
                  fullWidth
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'primary.main',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}></Grid>

              <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (action === 1) {
                        if (currentOption && salary && startDate && endDate) {
                          setTimeout(() => {
                            proceedAssoc({
                              companyId: currentOption,
                              salary,
                              startDate,
                              endDate,
                            });
                          }, 100);
                        } else {
                          alert('Preencha todos os campos obrigatórios!');
                        }
                        // associarColaborador();
                      } else {
                        // desassociarColaborador();
                      }
                    }}
                    style={{ marginRight: '10px' }}
                    // disabled={loadingAssoc}
                  >
                    Associar
                    {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'}  */}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>


            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (action == 1) {
                 associarColaborador(); 
                } else {
                 desassociarColaborador();
                } 
              }}
              style={{ marginRight: '10px' }}
            // disabled={loadingAssoc}
            >
              Sim, Associar
               {loadingAssoc ? 'Associando...' : 'Sim, Associar'} 
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            // disabled={loadingAssoc}
            >
              Cancelar
            </Button> */}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CollaboratorToCompany;
